import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap justify-center items-center relative md:-mt-[200px] md:space-x-80">
                <img className="w-[400px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FA%C3%B1os%20de%20experiencia%2Fexperience_20years.png?alt=media&token=e3088693-c839-46a3-a4b0-1a0e41edeb0f" alt="" />
                <img className="w-[320px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FA%C3%B1os%20de%20experiencia%2Fguarantee-preview.png?alt=media&token=164d5b4f-0b6c-4148-8ecb-264f2517178a" alt="" />
            </div>
        </div>
    );
}
export default HeroVideo;